import type { OnDestroy, OnInit } from '@angular/core';
import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { ModalService } from './modal.service';

@Directive({ selector: '[flModalTitle]' })
export class ModalTitleDirective implements OnInit, OnDestroy {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.viewContainerRef.clear();
    this.modalService.setTitleTemplate(this.templateRef);
  }

  ngOnDestroy(): void {
    this.modalService.setTitleTemplate(undefined);
  }
}
